.Paid-status {
    background-color: #d5f3bf;
    color: #487437;
    padding: 3px 10px;
}
.Pending-status {
    background-color: #f9b800;
    color: #3e3e3e;
    padding: 3px 10px;
}
.Cancel-status {
    background-color: #f95827;
    /* color: #d5f3bf; */
    color: white;
    padding: 3px 10px;
}

table {
    width: 100%;
    background-color: white;
}

table td {
    color: #807b68;
    font-weight: 600;
}

.table-container {
    max-height: 360px;
    overflow-y: auto;
}