

.sidebarmaindiv{
    /* background-color: aqua; */
    overflow: auto;
    width: 20%;
    height: 100vh;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.sidebarnavlinks{
    /* background-color: pink; */
    /* display: flex; */
    /* justify-content: space-around; */
    color: #1e3050;
    font-weight: 600;
    text-transform: capitalize;
    /* display: flex; */
    /* margin: 20px; */
    font-size: 18px;
    /* width: 100%; */
    text-decoration: none;
    /* margin-top: 20px; */
    /* padding: 10px; */
    /* display: block; */
}
.sidebarnavlinksdrop{
    width: 75%;
    background-color: transparent;
    border: none;
    outline: none;
    color: #1e3050;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 18px;
}
.navlinkdiv{
    margin: 8px auto;
    width: 90%;
    /* background-color: purple; */
    padding: 5px 5px;
    
}

.navlinkdiv a {
    color: black;
}

.navlinkdivactive{
    margin: 8px auto;
    width: 90%;
    /* border: 2px solid #1e3050; */
    background-color: #1e3050;
    color: white;
    border-radius: 5px;
    /* background-color: purple; */
    padding: 5px 5px;
}

.navlinkdivactive a  {
    color: white;
}

.rounded-circle{
    width: 30px;
    height: 30px;
    margin-top: 2.5px;
    margin-bottom: 2.5px;
    margin-left: 2.5px;
}
.dropdownportgrand{
    /* height: 50px; */
    border: 1px solid gainsboro;
    border-radius: 5px;
    margin: 5px auto;
display: flex;
justify-content: space-around;
color: gray;
/* margin-top: 10px; */
    width: 90%;
    font-size: 14px;
    /* background-color: #1e3050; */
    background-color: #ffffff;
    /* padding: '10px 5px'; */

}
#dropdownsidebar{
    background-color: transparent !important;
    color: gainsboro !important;
}

.metaimg{
    width: 40px;
    height: 25px;
    /* background-color: aqua; */
}
.navlinkicon{
    width: 25px;
    height: 25px;
    margin-right: 15px;
}
.metaimgandtextdiv{
    /* background-color: peru; */
    display: flex;
    font-size: 20px;
    line-height: 25px;
    width: 90%;
    margin: 0 auto;
    color: #1e3050;
    font-weight: 600;
}
