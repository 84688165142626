.icons-side-nav {
    overflow-y: hidden;
}

.side-nav-icon {
    width: 30px !important;
    height: 30px !important;
}

/* svg.side-nav-icon {
    fill: #464646;
} */