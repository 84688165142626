

.row{
    /* --bs-gutter-x:0px; */
}
.homedetaildiv{
    background-color: #e7ebfd;
    width: 100%;
}
.homedetailinnerdiv
{width: 90%;
    height: 70vh;
margin: 0 auto;
}
.backgroundimgport{
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.twoportimg{
    /* position: relative; */
    /* background-color: pink; */

/*  */
    /* ; */
    /* position: relative; */
    width: 100px;
    /* background-color: purple; */
}
.portoneimg{
    /* background-color: orange; */
    padding: 10px;
    /* width: 110px; */
    /* border-radius: 60px; */
    display: flex;
    /* margin: 10px; */
    position: relative;
    width: 130px;
    margin-top: -40px;
    margin-left: 8px;
    margin-bottom: 0px;
    margin-right: 5px;
    
}

.portimgsec{
    border: 6px solid white;
    
    width: 100px;
    /* position: absolute; */
    height: 100px;
    margin-left: -20px;
    /* left: 40px; */
    
  
}
.portprofiletext{
    /* background-color: brown; */
    /* margin-left: -20px !important; */
    padding-left: 0px !important;
}
.facebooksvg{
    border: 3px solid white;
    border-radius: 15px;
    position: absolute;
    top: 70px;
    right: 25px;
    z-index: 9999999;
    width: 25px;
    height: 25px;
}
.portimg{
    border: 6px solid white;
    width: 100px;
    position: relative !important;
    height: 100px;
}
.instagramsvg{
    position: absolute;
    left: 160px;
    background-color: white;
    border-radius: 8px;
    border: 3px solid white;
    width: 25px;
    height: 25px;
    /* position: relative; */
    top: 70px;
}
.portheading{
    font-size: 30px;
    font-weight: 600;
    /* background-color: brown; */
    margin-bottom: 0px;
}
a {
    text-decoration: none;
    line-height: 20px;
    /* background-color: brown; */
    /* color: blue; */
}
/* .divtwohomepage{ */
    /* background-color: peru; */
/* } */
.divtwobtnone{
    background-color: #0b78bb;
    width: 100%;
    border: none;
    outline: none;
    padding: 5px;
    margin-top: 5px;
    border-radius: 5px;
    color: white;
}
.divtwobtntwo{
    background-color: #f8f9fb;
    width: 100%;
    padding: 5px;
    /* color: grey; */
    margin-top: 5px;
    border-radius: 5px;
    outline: none;
    border: 1px solid gainsboro;
}
.divtwobtthree{
    background-color: #f8f9fb;
    /* color: grey; */
    border-radius: 5px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    outline: none;
    border: 1px solid gainsboro;
    margin: 5px;
}
.facebookviewerpercent{
    color: rgb(206, 6, 50);
}
.trendsbox{
    background-color: #f8f9fb;
    /* background-color: #ffffff; */
    /* background-color: #ffff66; */
    margin-top: 15px;
    /* padding: 5px; */
    padding-top: 20px;
    padding-bottom: 10px;
    margin-left: 1px;
    /* margin-right: 10px; */
    line-height: 13px;
}
.trendtext{
    font-weight: 700;
    font-size: 18px;
    margin-top: 10px;
    /* padding-top: 50px; */
}
.trendlineheighttext{
    color: gray;
    /* background-color: yellow; */
    font-size: 14px;
    /* line-height: 10px; */
}
.instaimgandtext{
    /* background-color: orangered; */
    margin-top: -10px;
}
.todoheading{
    font-size: 25px;
    font-weight: 700;
    margin-top: 50px;
}
.todounderlinetext{
    color: gray;
    /* background-color: yellow; */
    margin-top: -10px;
}