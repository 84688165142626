@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css');

* {
  margin: 0;
  padding: 0;
  list-style: none;
}

:root {
  --poppins: 'Poppins', sans-serif;
  --montserrat: 'Montserrat', sans-serif;
  --lora: 'Lora', serif;
  --raleway: 'Raleway', sans-serif;
  --bg-grey: #f2f2f2;
  --text-grey: #9594a1;
}

.font-poppins {
  font-family: var(--poppins) !important;
}

.font-montserrat {
  font-family: var(--montserrat) !important;
}

.font-lora {
  font-family: var(--lora) !important;
}

.font-raleway {
  font-family: var(--raleway);
}

.cursor-pointer {
  cursor: pointer;
}

.text-grey-color {
  color: var(--text-grey);
}

.bg-grey-color {
  background-color: var(--bg-grey);
}

.max-width-100vw {
  max-width: 100vw;
  width: 100%;
  overflow-x: hidden;
}

.max-height-100vh {
  max-height: 100vh;
  height: 100%;
  overflow-y: hidden;
}

.btn-grey {
  background-color: #e5e7e3;
  font-size: .9rem;
}

.btn-light-grey {
  background-color: #f0f0f0;
}

.btn-blue {
  background-color: #1f73f6;
  color: white;
}

button {
  opacity: 1;
  transition: 0.5s;
}

button:hover {
  opacity: 0.7;
}

.fs-7 {
  font-size: .8rem;
}

.select-grey-color input {
  border: none;
  background-color: #f0f0f0;
}

/* Imported CSS Files */
@import './styles/pages/paymentActivity.css';
@import './styles/pages/content.css';
@import './styles/pages/transactionDetails.css';
@import './styles/components/iconsSideNav.css';
@import './styles/components/dataTable.css';